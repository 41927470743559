import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfStore from "@/vue/domain/store/df-store";
import DfDayHoursActivityRange from "@/vue/domain/store/df-day-hours-activity-range";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfProvince from "@/vue/domain/store/df-province";
import DfDateRange from "@/vue/domain/store/df-date-range";
import moment from "moment";
import DfBrand from "@/vue/domain/brand/df-brand";

@Component({
  filters: {
    completeCityValue(city: string, postalCode: string, province: DfProvince): string {
      const provinceName: string = province ? province.name : "";
      const separator1: string = city && postalCode ? ", " : "";
      const separator2: string = provinceName && (city || postalCode) ? ", " : "";

      return `${city || ""}${separator1}${postalCode || ""}${separator2}${provinceName}`;
    },
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_SHORT_PATTER) : "";
    },
  },
})
export default class DfCardStoreComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private daysWeekIndices: Array<number> = [0, 1, 2, 3, 4, 5, 6];

  @Prop({ type: Object, default: () => ({}) })
  store!: DfStore;

  @Prop({ type: Boolean, default: false })
  full!: boolean;

  @Prop({ type: Boolean, default: true })
  showStoreName: boolean;

  @Prop({ type: Boolean, default: true })
  showStoreCity: boolean;

  @Prop({ type: Boolean, default: true })
  showLocationPinLogo: boolean;

  @Prop({ type: Boolean, default: false })
  geolocationEnabled: boolean;

  get locationPinLogoUrl(): string {
    return `${Utils.getPublicPath()}/assets/location-pin-alt.png`;
  }

  get openingTimes(): Array<DfDayHoursActivityRange> {
    return this.store.openingTimes;
  }

  get weekOpeningTimes(): Array<Array<DfDayHoursActivityRange>> {
    return this.daysWeekIndices.map((dayIndex: number) => {
      return this.openingTimes.filter((openingTime: DfDayHoursActivityRange) => openingTime.dayOfWeek.enumOrdinal === dayIndex);
    });
  }

  get todayOpeningTimes(): Array<DfDayHoursActivityRange> {
    const date: any = new Date();
    const todayIndex: number = date.getDay() - 1 < 0 ? 6 : date.getDay() - 1;

    return this.openingTimes.filter((openingTime: DfDayHoursActivityRange) => openingTime.dayOfWeek.enumOrdinal === todayIndex);
  }

  get isOpen(): boolean {
    let isOpen: DfDayHoursActivityRange = null;
    if (this.todayOpeningTimes.length > 0) {
      const date: any = new Date();
      const hours: string = date.getHours().toString().padStart(2, "0");
      const minutes: string = date.getMinutes().toString().padStart(2, "0");

      isOpen = this.todayOpeningTimes.find((openingTime: DfDayHoursActivityRange) => {
        return openingTime.startTime <= `${hours}:${minutes}` && openingTime.endTime >= `${hours}:${minutes}`;
      });
    }

    return !!isOpen;
  }

  get customerCareUrl(): string {
    const storeCare: string = Utils.getPropertyValue(this.store, Utils.PROPERTY_STORE_CUSTOMER_CARE_URL, `STRING`);
    const brandCare: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_STORE_CUSTOMER_CARE_URL, `STRING`);

    return storeCare ? storeCare : brandCare;
  }

  get customerCareLabel(): string {
    const storeCareLabel: string = Utils.getPropertyValue(this.store, Utils.PROPERTY_STORE_CUSTOMER_CARE_LABEL, `STRING`);
    const brandCareLabel: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_STORE_CUSTOMER_CARE_LABEL, `STRING`, this.$t("df-store-card.customer-care-label"));

    return storeCareLabel ? storeCareLabel : brandCareLabel;
  }

  get storeOpenings(): Array<DfDateRange> {
    return this.$store.getters.storeOpenings;
  }

  get storeClosures(): Array<DfDateRange> {
    return this.$store.getters.storeClosures;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get showMobileStore(): boolean {
    return Utils.showMobileStore() || this.$route.name === "store-selection";
  }

  private rangeDateStart(dateRange: DfDateRange): string {
    return dateRange.startDate === dateRange.endDate
      ? this.$t("df-store-card.special-date-single-day", {
          0: this.$options.filters!.dateFormat(dateRange.startDate),
        }).toString()
      : this.$t("df-store-card.special-date-multiple-day", {
          0: this.$options.filters!.dateFormat(dateRange.startDate),
          1: this.$options.filters!.dateFormat(dateRange.endDate),
        }).toString();
  }

  private onClick() {
    return this.$emit("storeCardClick", this.store);
    Utils.setAnaliticsTraker({
      event: "show_store_info",
      origin: "smt-digitalflyer-ui",
      storeAlias: this.store.alias,
      storeCode: this.store.code,
      storeName: this.store.name,
    });
  }

  private onIconClick() {
    Utils.setAnaliticsTraker({
      event: "open_google_map",
      origin: "smt-digitalflyer-ui",
      storeAlias: this.store.alias,
      storeCode: this.store.code,
      storeName: this.store.name,
    });
    return this.$emit("storeCardIconClick", this.store);
  }
}
